export default {
  OfferModule: () => import(/* webpackChunkName: "OfferModule" */'~/react/OfferModule'),
  MemberStatus: () => import(/* webpackChunkName: "MemberStatus" */'~/react/shared/MemberStatus'),
  SeeMore: () => import(/* webpackChunkName: "SeeMore" */'~/react/Zone/SeeMore'),
  NewsletterHub: () => import(/* webpackChunkName: "NewsletterHub" */'~/react/NewsletterHub'),
  Newsletter: () => import(/* webpackChunkName: "Newsletter" */'~/react/Newsletter'),
  PrivacyLinks: () => import(/* webpackChunkName: "PrivacyLinks" */'~/react/PrivacyLinks'),
  CookieConsent: () => import(/* webpackChunkName: "CookieConsent" */'~/react/CookieConsent'),
  LatestInZone: () => import(/* webpackChunkName: "LatestInZone" */'~/react/LatestInZone'),
  Image: () => import(/* webpackChunkName: "Image" */'~/react/Card/Image'),
  SubNav: () => import(/* webpackChunkName: "SubNav" */'~/react/shared/SubNav'),
  LeftNavTop: () => import(/* webpackChunkName: "LeftNavTop" */'~/react/shared/LeftNavTop'),
  Modal: () => import(/* webpackChunkName: "Modal" */'~/react/Modal'),
  SocialButtons: () => import(/* webpackChunkName: "SocialButtons" */'~/react/SocialButtons'),
  FeedbackToaster: () => import(/* webpackChunkName: "FeedbackToaster" */'~/react/FeedbackToaster'),
  VotingToaster: () => import(/* webpackChunkName: "VotingToaster" */'~/react/VotingToaster'),
  TimeAgo: () => import(/* webpackChunkName: "TimeAgo" */'~/react/shared/TimeAgo'),
  UpdatedTimestamp: () => import(/* webpackChunkName: "UpdatedTimestamp" */'~/react/Entry/Header/HeaderItem/UpdatedTimestamp'),
  Comments: () => import(/* webpackChunkName: "Comments" */'~/react/Entry/Comments'),
  Bookmark: () => import(/* webpackChunkName: "Bookmark" */'~/react/Entry/Bookmark'),
  YouTube: () => import(/* webpackChunkName: "YouTube" */'~/react/Entry/Body/ContentListItem/Embed/Video/YouTube'),
  Vidible: () => import(/* webpackChunkName: "Vidible" */'~/react/Entry/Body/ContentListItem/Embed/Video/Vidible'),
  Connatix: () => import(/* webpackChunkName: "Connatix" */'~/react/Entry/Body/ContentListItem/Embed/Video/Connatix'),
  Looper: () => import(/* webpackChunkName: "Looper" */'~/react/Entry/Body/ContentListItem/Embed/Video/Looper'),
  Twitter: () => import(/* webpackChunkName: "Twitter" */'~/react/Entry/Body/ContentListItem/Embed/Twitter/TwitterComponent'),
  Corrections: () => import(/* webpackChunkName: "Corrections" */'~/react/Entry/Corrections'),
  HighlineNavControls: () => import(/* webpackChunkName: "HighlineNavControls" */'~/react/shared/HighlineNavControls'),
  SpoilsNav: () => import(/* webpackChunkName: "SpoilsNav" */'~/react/spoils/Nav'),
  ChangeEmail: () => import(/* webpackChunkName: "ChangeEmail" */'~/react/ChangeEmail'),
  EditProfile: () => import(/* webpackChunkName:"EditProfile" */'~/react/EditProfile'),
  EditionsMenu: () => import(/* webpackChunkName: "EditionsMenu" */'~/react/shared/EditionsMenu'),
  Banner: () => import(/* webpackChunkName: "Banner" */'~/react/Entry/Banner'),
  Liveblog: () => import(/* webpackChunkName: "Liveblog" */'~/react/Entry/Body/ContentListItem/Liveblog'),
  Zone: () => import(/* webpackChunkName: "Zone" */'~/react/Zone'),
  FrontSupportHuffpost: () => import(/* webpackChunkName: "FrontSupportHuffpost" */'~/react/FrontSupportHuffpost'),
  Contribute: () => import(/* webpackChunkName: "Contribute" */'~/react/Contribute'),
  AlertBanner: () => import(/* webpackChunkName: "AlertBanner" */'~/react/shared/AlertBanner'),
  NotificationCenter: () => import(/* webpackChunkName: "NotificationCenter" */'~/react/shared/NotificationCenter'),
  HuffPal: () => import(/* webpackChunkName: "HuffPal" */'~/react/HuffPal'),
  GameIframe: () => import(/* webpackChunkName: "GameIframe" */'~/react/GameIframe'),
  GameExpandButton: () => import(/* webpackChunkName: "GameExpandButton" */'~/react/GameExpandButton'),
};
